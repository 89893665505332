import { IconComponent } from '@abm/icon';
import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { CategoryType } from '@type/categories.type';
import { BaseTabComponent } from '../base-tab/base-tab.component';

@Component({
  selector: 'app-tab',
  imports: [IconComponent],
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss', './../base-tab/base-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent extends BaseTabComponent {
  hideCategory = output<CategoryType>();

  clickHandler(event: Event): void {
    const c = this.category();
    !!c && this.activeCategory.value.set(c);
  }

  closeTab(event: MouseEvent) {
    const c = this.category();
    !!c && this.hideCategory.emit(c);
    event.stopImmediatePropagation();
    event.preventDefault();
  }
}
