import { IconComponent } from '@abm/icon';
import { OverlayRef } from '@angular/cdk/overlay';
import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, inject, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatePipe } from '@lf/translate-core';
import { LinkComponent } from '@lf/ui';
import { MENU_ITEM } from '@tokens/menu-item.token';

@Component({
  selector: 'app-submenu',
  imports: [UpperCasePipe, RouterModule, IconComponent, TranslatePipe, LinkComponent],
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuComponent {
  @Input()
  menuItem = inject(MENU_ITEM);

  @Input()
  hideOnFocusLost = true;

  overlayRef = inject(OverlayRef);

  @HostListener('click')
  @HostListener('mouseleave')
  mouseleave(): void {
    this.hideOnFocusLost && this.overlayRef?.dispose();
  }
}
